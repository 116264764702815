import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  constructor() { }
  errorHandler(err:string):string{
    //errorHandler
    console.log("errore"+JSON.stringify(err))
    return "errore"
  }
}
