import { Sessione } from './../models/sessione';

import { HttpErrorService } from './http-error.service';

import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {


  apiUrl="https://www.wikimiti.com/cercapost/appsessioni/"
  token="MTA1MDlkdWlkC227DA3611024228643D1258E867FA3B17B1DDB0CFAB457F9EE6FE757E8C0F89E0F83D53E06C70B8";
  url="https://shelly-41-eu.shelly.cloud";
  device="c45bbe57eca4"


  constructor(private http:HttpClient, private httpError:HttpErrorService) { }

  getDisponibilita(coach:number): Observable<Sessione[]|string> {
    console.log("caricaPercorsi")
   const httpOptions = {
     headers: new HttpHeaders({
       'Content-Type':  'application/x-www-form-urlencoded',
       'Access-Control-Allow-Origin': 'http://localhost:8100, http://localhost:8200, http://localhost:8101'
         })
   };
     let body =  "coach="+coach
    
 
      return this.http.post<Sessione[]|string>(this.apiUrl+'getDisponibilita', body, httpOptions)
                   .pipe(catchError(this.httpError.errorHandler));
  
  }

  
  insSessione(mail:string,tel:string, sessione:number, tipo:number): Observable<any> {
    console.log("caricaPercorsi")
   const httpOptions = {
     headers: new HttpHeaders({
       'Content-Type':  'application/x-www-form-urlencoded',
       'Access-Control-Allow-Origin': 'http://localhost:8100, http://localhost:8200, http://localhost:8101'
         })
   };
     let body =  "mail="+mail+"&telefono="+tel+"&sessione="+sessione+"&tipo="+tipo
    
 
      return this.http.post<any>(this.apiUrl+'insSessione', body, httpOptions)
                   .pipe(catchError(this.httpError.errorHandler));
  
  }

 
}

